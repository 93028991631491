import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import {
  alignSelf,
  fontSize,
  lineHeight,
  space,
  themeGet,
  textAlign,
} from 'styled-system';
import styled from '@emotion/styled';
import { Box, Flex as RooFlex } from '@qga/roo-ui/components';
import { getTotalTravellers } from '@/libs/utils';
import Travellers from '@/libs/propTypes/Travellers';
import Heading from '@/components/Heading';
import Text from '@/components/Text';
import Price from '@/propTypes/Price';
import getOccupantsSentence from '@/utils/getOccupantsSentence';
import { formatMoneyWithoutDollar } from '../../utils/formatMoney';
import SavingsCvp from '@/components/SavingsCvp';
import { PRICING_STRATEGIES } from '@/constants';
import { useOptimizelyFeatureFlag } from '@/libs/v2/contexts/optimizely';

const Flex = styled(RooFlex)`
  ${alignSelf}
`;

const StyledText = styled(Heading.h5)`
  display: inline;
  color: inherit;
  line-height: ${themeGet('lineHeights.tight')};
  white-space: nowrap;
  ${space};
  ${fontSize};
  ${lineHeight};
  ${textAlign};
`;

const TotalPrice = styled(Heading.h3)`
  display: inline;
  color: inherit;
  line-height: 0.8;
  ${space};
  ${fontSize};
  ${lineHeight};
  ${textAlign};
`;

const PricePerTraveller = ({
  displayTravellerTypes,
  exact = false,
  inverse = false,
  perAdult = undefined,
  perTraveller,
  priceForAdult = false,
  signedPrice = false,
  total,
  travellers,
  valuedAtTotal,
  exactTotalPrice = false,
  alignContent,
  hidePerPersonPrice,
}) => {
  const totalTravellers = getTotalTravellers(travellers);

  const [decision, isReady] = useOptimizelyFeatureFlag(
    'jetstar-holidays-pricing-strategy',
  );
  let isTotalPriceFlagOn = false;

  if (isReady) {
    isTotalPriceFlagOn =
      !!decision?.enabled &&
      decision.variables['strategy'] === PRICING_STRATEGIES.TOTAL;
  }
  const showTotalPrice = isTotalPriceFlagOn || hidePerPersonPrice;

  return (
    <>
      {perTraveller && (
        <Flex
          data-testid="PER_TRAVELLER_PRICE"
          alignItems="center"
          justifyContent={alignContent}
          my={2}
          color={inverse ? 'white' : 'primary'}
        >
          {signedPrice ? (
            <Box mr="2">
              <StyledText fontSize="xl">
                {perTraveller.amount >= 0 ? '+' : '-'}
              </StyledText>
            </Box>
          ) : (
            <StyledText textAlign="right" mr="1" fontSize={['xs', null, 'sm']}>
              {!exactTotalPrice && 'From'}
              {exactTotalPrice && showTotalPrice && 'Total'}
            </StyledText>
          )}
          <Flex>
            <Flex alignSelf="flex-start">
              <StyledText textAlign="right" fontSize={['xs', null, 'sm']}>
                $
              </StyledText>
            </Flex>
            <TotalPrice fontSize={['24px', null, '32px']}>
              {`${formatMoneyWithoutDollar(
                Math.abs(
                  // eslint-disable-next-line no-nested-ternary
                  showTotalPrice && total
                    ? total.amount
                    : priceForAdult
                      ? perAdult?.amount
                        ? perAdult?.amount
                        : perTraveller.amount
                      : perTraveller.amount,
                ),
                { wholeDollars: !exact },
              )}`}
            </TotalPrice>
            <Flex alignSelf="flex-start">
              <StyledText
                textAlign="right"
                fontSize={['xs', null, 'sm']}
                mr="1"
              >
                ^
              </StyledText>
            </Flex>
          </Flex>
          {!showTotalPrice && (
            <StyledText textAlign="right" fontSize={['xs', null, 'sm']}>
              {`${priceForAdult ? '/adult' : '/person'}`}
            </StyledText>
          )}
        </Flex>
      )}
      {total && (
        <Flex
          data-testid="TOTAL_PRICE"
          justifyContent={alignContent}
          color={inverse ? 'white' : 'primary'}
        >
          <Flex alignItems="center">
            {!showTotalPrice && (
              <Flex alignItems="center" justifyContent="center">
                <StyledText fontSize={['xs', null, 'sm']} lineHeight="1.35">
                  $
                </StyledText>
                <StyledText
                  fontSize={signedPrice ? '2xl' : ['xs', null, 'sm']}
                  mr="1"
                >
                  {formatMoneyWithoutDollar(total.amount, {
                    wholeDollars: !exact,
                  })}
                </StyledText>
              </Flex>
            )}

            {!!totalTravellers && (
              <StyledText fontSize={['xs', null, 'sm']} bold color="primary">
                {displayTravellerTypes && !showTotalPrice
                  ? `for ${getOccupantsSentence(travellers)}`
                  : `for ${pluralize('traveller', totalTravellers, true)}`}
              </StyledText>
            )}
          </Flex>
        </Flex>
      )}
      {valuedAtTotal?.amount > 0 && (
        <Flex justifyContent={alignContent} mt={1}>
          <Flex alignItems="baseline">
            <Text
              fontSize={['xxs', null, 'xs']}
              color={inverse ? 'white' : 'text.body'}
            >
              Valued at $
              {formatMoneyWithoutDollar(valuedAtTotal.amount, {
                wholeDollars: !exact,
              })}
            </Text>
            <SavingsCvp total={total} valuation={valuedAtTotal} />
          </Flex>
        </Flex>
      )}
    </>
  );
};

PricePerTraveller.defaultProps = {
  displayTravellerTypes: false,
  exact: false,
  inverse: false,
  perAdult: undefined,
  perTraveller: undefined,
  priceForAdult: false,
  signedPrice: false,
  total: undefined,
  travellers: undefined,
  valuedAtTotal: undefined,
  exactTotalPrice: false,
  hidePerPersonPrice: false,
  alignContent: 'flex-end',
};

PricePerTraveller.propTypes = {
  displayTravellerTypes: PropTypes.bool,
  exact: PropTypes.bool,
  inverse: PropTypes.bool,
  perAdult: PropTypes.shape(Price),
  perTraveller: PropTypes.shape(Price),
  priceForAdult: PropTypes.bool,
  signedPrice: PropTypes.bool,
  total: PropTypes.shape(Price),
  travellers: PropTypes.shape(Travellers),
  valuedAtTotal: PropTypes.shape(Price),
  exactTotalPrice: PropTypes.bool,
  hidePerPersonPrice: PropTypes.bool,
  alignContent: PropTypes.string,
};

export default PricePerTraveller;
